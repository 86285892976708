/*eslint-disable*/
import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Contacto() {
  return (
    <>
    <IndexNavbar fixed />
        <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100" style={{marginTop: "5rem"}}>
            <div className="container mx-auto overflow-hidden pb-20">
                <div className="flex flex-wrap items-center" id="preparacion_para_el_parto">
                    <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
                        <h3 className="text-3xl mb-2 font-semibold leading-normal">
                            Contacto
                        </h3>
                        <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                            Localízanos en nuestras redes sociales <br/><br/>
                            <a href="https://www.instagram.com/hsconstrucciones/" target="_blank" className="iconsContact">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://www.facebook.com/hsconstrucciones/" target="_blank" className="iconsContact">
                                <i className="fab fa-facebook-square"></i>
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=5216621494293" target="_blank" className="iconsContact">
                                <i className="fab fa-whatsapp"></i>
                            </a>
                            <a href="mailto:administracion@hsconstrucciones.com" target="_blank" className="iconsContact">
                                <i className="fa fa-envelope"></i>
                            </a>
                            <a href="tel:6624361060" target="_blank" className="iconsContact">
                                <i className="fas fa-phoone"></i>
                            </a>
                        </p>
                    </div>
                    <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
                        <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3486.8081121278633!2d-110.99264648484719!3d29.081814770354764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce840ce971608b%3A0xdadd35ba5c72e449!2sQuinta%20Hermosa%2041%2C%20Las%20Quintas%2C%2083240%20Hermosillo%2C%20Son.!5e0!3m2!1ses-419!2smx!4v1630063239176!5m2!1ses-419!2smx" width="600" height="450" style={{border: 0}} allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
  );
}
