/*eslint-disable*/
import React from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-32 sm:pt-0">
              <h2 className="font-semibold text-4xl text-blueGray-600">
                Bienvenido a HS Construcciones
              </h2>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                Un equipo de profesionales dedicados a la industria, niverlaciones, creación y supervisión de proyectos, demolicion, infraestructura de todo tipo: público, minera, agrícola y privado, comercialización y acarreo de materiales para la construcción.
              </p>
              <div className="mt-12">
                <a
                  href="#servicios"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Nuestros Servicios
                </a>
              </div>
            </div>
          </div>
        </div>
        <img
          className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
          src={require("assets/img/pattern_react.png").default}
          alt="HS Construcciones"
        />
      </section>

      <section className="block relative z-1" style={{marginTop: "20rem", backgroundColor: "#f9c8b0"}}>
        <div className="container mx-auto">
          <div className="justify-center flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4  -mt-24">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center" style={{color: "#1d100c"}}>
                    Misión
                  </h5>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style={{background: "transparent"}}>
                      <p className="informacion">
                        Es satiisfacer las necesidades de nuestros cliientes antes, durante y después de finalizar
                        el proyecto, cumpliendo con los estándares de calidad y plazos fijados por éste, generando 
                        de esta manera una relación firme y duradera.
                      </p>
                    </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center" style={{color: "#1d100c"}}>
                    Visión
                  </h5>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style={{background: "transparent"}}>
                      <p className="informacion">
                        Ser líderes de la industria de ingeniería y construcción, a nivel nacioinal. Manteniendo un 
                        excelente de nivel de biestar y ambiente para el personal, y un alto grado de identificación
                        de éste con la empresa.
                      </p>
                    </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center" style={{color: "#1d100c"}}>
                    Valores
                  </h5>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style={{background: "transparent"}}>
                      <p className="informacion">
                        Honestidad y Ética, Responsabilidad, Pasión por el Trabajo y Esfuerzo, Confianza y buena 
                        Voluntad, Trabajo en Equipo, Cultura de Calidad, Respeto a las personas y el Entorno Físico,
                        Tolerancia a la diversidad de ideas.
                      </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100" id="servicios">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
              <i className="fas fa-cog text-xl"></i>
            </div>
            <h3 className="text-3xl mb-2 font-semibold leading-normal">
              Nuestros servicios
            </h3>
            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
              Contamos con una gran variedad de servicios y todos con la calidad que nos identifica.
            </p>
            <ul className="listServicios">
              <li>Obra Civil</li>
              <li>Elaboración de proyectos públicos, privados y agrícolas</li>
              <li>Urbanización</li>
              <li>Movimiento de Tierras</li>
              <li>Desmontes</li>
              <li>Demoliciones</li>
              <li>Nivelaciión y limpieza de terrenos</li>
              <li>Soldadura estructural</li>
              <li>Obras para irrigación ganadera o agrícola</li>
              <li>Herrería en general</li>
              <li>Revestimiento de concreto</li>
              <li>Puentes</li>
              <li>Instalaciones eléctricas</li>
              <li>Carreteras</li>
              <li>Terracería</li>
              <li>Excavaciones</li>
              <li>Fletes y acarreos en general</li>
              <li>Fabricación de estructuras metálicas</li>
              <li>Laminados</li>
              <li>Proyecto y cálculo estructural</li>
              <li>Mantenimiento en general</li>
            </ul>
          </div>
          <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-xl"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={require("assets/img/servicios.png").default}
              />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
