/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Cursos() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100" style={{marginTop: "5rem"}}>
        <div className="container mx-auto overflow-hidden pb-20">
            <h2 className="font-semibold text-4xl" style={{color: "#f19959", textAlign: "center", marginTop: "20px"}}>Cursos</h2>
            <div className="flex flex-wrap items-center" id="preparacion_para_el_parto">
                <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto">
                    <h1 style={{color: "#ea772a",fontSize: "1.3rem", paddingTop: "10px",paddingBottom: "10px"}}>Temas:</h1>
                    <ul style={{color: "#556275"}}>
                        <li>*Anatomía y Fisiología del Parto</li>
                        <li>*Molestias y confort en el tercer trimestre</li>
                        <li>* Señales de alarma en el tercer trimestre</li>
                        <li>* Señales de trabajo de parto</li>
                        <li>* Tipos de contracciones y conteo de contracciones</li>
                        <li>* Etapas del trabajo de parto “El viaje físico y emocional“</li>
                        <li>* Alternativas naturales para el dolor</li>
                        <li>* Parto en Movimiento</li>
                        <li>* Momento de ir al hospital</li>
                        <li>* Acompañamiento efectivo en el trabajo de parto y parto</li>
                        <li>* Pujo espontaneo y Pujo dirigido</li>
                        <li>* Consciencia del suelo pélvico</li>
                        <li>* Intervenciones médicas</li>
                        <li>* Cesárea</li>
                        <li>* Alumbramiento</li>
                        <li>* Primera hora del bebe y sus necesidades</li>
                        <li>* Posparto Físico y Emocional</li>
                        <li>* Paternidad y sus tipos</li>
                        <li>* Lactancia, técnicas y principales retos</li>
                        <li>* Apariencia del recién nacido y sus primeros cuidados</li>
                        <li>* Introducción al porteo</li>
                        <li>* Seguridad en el Auto</li>
                        <li>* Plan de parto</li>
                        <li>y mucho más...</li>
                    </ul>
                </div>
                <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
                    <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                        <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                            <i className="fas fa-sitemap"></i>
                        </div>
                        <h3 className="text-3xl mb-2 font-semibold leading-normal">
                            Cursos de preparación para el parto completo.
                        </h3>
                        <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                            A quien va dirigido:<br/>
                            Para parejas y/o acompañantes que desean prepararse para una experiencia de nacimiento positiva, segura y saludable.<br/><br/>
                            Se recomienda tomarse antes de la semana 33 de embarazo.
                        </p>
                        <table className="tablaCursos">
                            <tr className="titles">
                                <th></th>
                                <th>Curso 1</th>
                                <th>Curso 2</th>
                                <th>Curso 3</th>
                            </tr>
                            <tr>
                                <td className="borderRight">Clase 1</td>
                                <td>4 de ago</td>
                                <td>16 de sep</td>
                                <td>3 de nov</td>
                            </tr>
                            <tr>
                                <td className="borderRight">Clase 2</td>
                                <td>11 de ago</td>
                                <td>22 de sep</td>
                                <td>10 de nov</td>
                            </tr>
                            <tr>
                                <td className="borderRight">Clase 3</td>
                                <td>18 de ago</td>
                                <td>29 de sep</td>
                                <td>17 de nov</td>
                            </tr>
                            <tr>
                                <td className="borderRight">Clase 4</td>
                                <td>25 de ago</td>
                                <td>6 de oct</td>
                                <td>24 de nov</td>
                            </tr>
                            <tr>
                                <td className="borderRight">Clase 5 <br/>Posparto y Lactancia</td>
                                <td>1 de sep</td>
                                <td>13 de oct</td>
                                <td>1 de dic</td>
                            </tr>
                            <tr>
                                <td className="borderRight">Clase 6</td>
                                <td>8 de sep</td>
                                <td>20 de oct</td>
                                <td>8 de dic</td>
                            </tr>
                            <tr>
                                <td className="borderRight">Clase de abuelos</td>
                                <td>14 de sep</td>
                                <td>27 de oct</td>
                                <td>15 de dic</td>
                            </tr>
                        </table>
                        <div className="nota">
                            <p>De regalo: Clase de abuelos Online</p>
                            <h1>Costo $2,500.00 (presencial)</h1>
                            <h1>Costo $1,500.00 (en línea)</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap items-center pt-32" id="preparacion_para_el_parto_intensivo">
                <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
                    <div className="justify-center flex flex-wrap relative">
                        <div className="my-4 w-full lg:w-6/12 px-4">
                            <div className="bg-red-600 shadow-lg rounded-lg text-center p-8">
                                <p className="text-lg text-white mt-4 font-semibold">
                                    Curso presencial <br/>12 y 13 de agosto
                                </p>
                            </div>
                            <div className="bg-lightBlue-500 shadow-lg rounded-lg text-center p-8 mt-8">
                                <p className="text-lg text-white mt-4 font-semibold">
                                    Curso presencial <br/>9 y 10 de septiembre
                                </p>
                            </div>
                            <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-8 mt-8">
                                <p className="text-lg text-white mt-4 font-semibold">
                                    Curso presencial <br/>7 y 8 de octubre
                                </p>
                            </div>
                            <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-8 mt-8" style={{background: "#f9ae9d"}}>
                                <p className="text-lg text-white mt-4 font-semibold">
                                    Curso presencial <br/>11 y 12 de noviembre
                                </p>
                            </div>
                        </div>
                        <div className="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                            <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-8">
                                <p className="text-lg text-white mt-4 font-semibold">
                                    Curso en línea <br/>23 y 24 de agosto
                                </p>
                            </div>
                            <div className="bg-red-700 shadow-lg rounded-lg text-center p-8 mt-8">
                                <p className="text-lg text-white mt-4 font-semibold">
                                    Curso en línea <br/>27 y 28 de septiembre
                                </p>
                            </div>
                            <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-8 mt-8">
                                <p className="text-lg text-white mt-4 font-semibold">
                                    Curso en línea <br/>25 y 26 de octubre
                                </p>
                            </div>
                            <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-8 mt-8" style={{background: "#7295d5"}}>
                                <p className="text-lg text-white mt-4 font-semibold">
                                    Curso en línea <br/>22 y 23 de noviembre
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
                    <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                        <i className="fas fa-bullhorn"></i>
                    </div>
                    <h3 className="text-3xl mb-2 font-semibold leading-normal">
                        Curso intensivo de preparación para el parto
                    </h3>
                    <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                        Dirigido a: <br/>
                        Curso para mujeres embarazadas en tercer trimestre y sus parejas y/o
                        acompañante que desean recibir a su bebé informados y con herramientas para
                        un nacimiento seguro, saludable y positiva.
                    </p>
                    <h1 style={{color: "#ea772a",fontSize: "1.3rem", paddingTop: "10px",paddingBottom: "10px"}}>Temas:</h1>
                    <ul style={{color: "#556275"}}>
                        <li>En este curso intensivo aprenderás:</li>
                        <li>* Señales de alerta en 3er trimestre</li>
                        <li>* Anatomía y fisiología del parto.</li>
                        <li>* Fases del trabajo de parto</li>
                        <li>* Alternativas naturales para el dolor</li>
                        <li>* Parto en movimiento</li>
                        <li>* El momento de ir al hospital</li>
                        <li>* Las intervenciones médicas</li>
                        <li>* Necesidades inmediatas del recién nacido</li>
                        <li>* Inicio de la Lactancia</li>
                    </ul><br/>
                    <p>2 sesiones de 3 horas</p><br/>
                    <h1 style={{color: "#ea772a"}}>El costo presencial es de $1,800.</h1><br/>
                    <h1 style={{color: "#ea772a"}}>El costo en línea es de $1,000.</h1>
                </div>
            </div>
        </div>

        <div className="container mx-auto px-4 pb-32 pt-48" id="gym_de_embarazo">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                    <i className="fas fa-dumbbell"></i>
                </div>
                <h3 className="text-3xl font-semibold">
                    GYM de Embarazo
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Trabajamos estiramiento, cardio de bajo impacto, fuerza por medio de ligas o mancuernas y peso
                    muerto, para fortalecer extremidades superiores e inferiores, relajación y respiración. También
                    trabajamos suelo pélvico y la movilidad de la pelvis.<br/><br/>
                    <h1 style={{color: "#ea772a"}}>El costo es de $500 mensuales.</h1><br/>
                    2 clases por semana <br/><br/>
                    Estamos certificadas en Actividad física para embarazadas y siempre cuidamos la flexibilidad propia del embarazo y posturas para evitar lesiones.
                </p>
              </div>
            </div>

            <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-xl"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={require("assets/img/documentation.png").default}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
