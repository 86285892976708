/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Certificados() {
  return (
    <>
    <IndexNavbar fixed />
        <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100" style={{marginTop: "5rem"}}>
            <div className="container mx-auto overflow-hidden pb-20">
                <div className="flex flex-wrap items-center" id="preparacion_para_el_parto">
                    <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
                        <h3 className="text-3xl mb-2 font-semibold leading-normal">
                            Certificados
                        </h3>
                        <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                            En constante capacitación para brindarte la mejor experiencia que mereces.
                        </p>
                    </div>
                    <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
                        <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                            <img
                                alt="..."
                                src={require("assets/img/certificados/AcompañanteDeParto.png").default}
                                className="w-full align-middle rounded shadow-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="block relative z-1 bg-blueGray-600">
            <div className="container mx-auto">
                <div className="justify-center flex flex-wrap">
                    <div className="w-full lg:w-12/12 px-4  -mt-24">
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/ActividadFisica.png").default}
                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/AsesoriaDeLactancia.png").default}
                                    />
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/PsicoterapiaGestalt.png").default}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex flex-wrap" style={{marginTop: 100}}>
                    <div className="w-full lg:w-12/12 px-4 -mt-24">
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/CertificadoLamaze.png").default}
                                    />
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/CertificadoLamaze2.png").default}
                                    />
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/CertificadoLamaze3.png").default}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex flex-wrap" style={{marginTop: 100}}>
                    <div className="w-full lg:w-12/12 px-4 -mt-24">
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/OrientacionFamiliar1.png").default}
                                    />
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/OrientacionFamiliar2.png").default}
                                    />
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/OrientacionFamiliar3.png").default}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex flex-wrap" style={{marginTop: 100}}>
                    <div className="w-full lg:w-12/12 px-4 -mt-24">
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/SpinningBabies.png").default}
                                    />
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/Constancia1.png").default}
                                    />
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/Constancia2.png").default}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex flex-wrap" style={{marginTop: 100}}>
                    <div className="w-full lg:w-12/12 px-4 -mt-24">
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/Titulo1.png").default}
                                    />
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-4">
                                <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                        src={require("assets/img/certificados/Titulo2.png").default}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
  );
}
