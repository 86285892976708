import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-blueGray-200 pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold">¡Contáctanos!</h4>
              <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                Síguenos en nuestras redes
              </h5>
              <div className="mt-6 lg:mb-0 mb-6">
                <button
                  className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button">
                    <a href="https://www.instagram.com/hsconstrucciones/" target="_blank">
                      <i className="fab fa-instagram"></i>
                    </a>
                </button>
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <a href="https://www.facebook.com/hsconstrucciones/" target="_blank">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                </button>
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <a href="https://api.whatsapp.com/send?phone=5216621494293" target="_blank">
                    <i className="fab fa-whatsapp-square"></i>
                  </a>
                </button>
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <a href="mailto:administracion@hsconstrucciones.com" target="_blank">
                    <i className="fa fa-envelope-square"></i>
                  </a>
                </button>
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <a href="tel:6624361060" target="_blank">
                    <i className="fas fa-phone-square"></i>
                  </a>
                </button>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-6/12 px-4 ml-auto">
                  <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                    Visita nuestra oficina
                  </span>
                  <ul className="list-unstyled">
                    <li style={{color: "#000"}}>
                      Quinta Hermosa #41 Colonia Las Quintas, C.P. 83240 / Hermosillo Sonora, México.
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-6/12 px-4 ml-auto">
                  <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                    Enlaces
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="font-semibold block pb-2 text-sm"
                        href="#"
                      >
                        Términos y Condiciones
                      </a>
                    </li>
                    <li>
                      <a
                        className="font-semibold block pb-2 text-sm"
                        href="#"
                      >
                        Política de Privacidad
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-blueGray-500 font-semibold py-1">
                Desarrollado por <a href="https://ramico.com.mx" target="_blank">
                    <label style={{color: "rgb(106, 177, 252)",cursor: "pointer"}}>ra</label>
                    <label style={{color: "rgb(223, 92, 92)",cursor: "pointer"}}>mi</label>
                    <label style={{color: "rgb(216, 216, 216)",cursor: "pointer"}}>co</label>
                  </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
